import React, { Component, Fragment } from "react";

import ScrollToTop from "react-scroll-up";
import Slider from "react-slick";
import { slideSlick } from "../page-demo/script";
import Header from "../component/header/HeaderFive";
import FooterTwo from "../component/footer/FooterTwo";
import Team from "../blocks/team/TeamTwo";
import { Element, Link } from "react-scroll";
import Helmet from "../component/common/Helmet";
import { FiCast, FiLayers, FiChevronUp, FiHeart } from "react-icons/fi";
// import CounterOne from "../elements/counters/CounterOne";

const SlideList = [
  {
    textPosition: "text-right",
    bgImage: "bg_image--1",
    category: "",
    // title: "The Proper Care.",
    // description: "Recover from your pain at Laredo Family Health Center.",
    // buttonText: "Contact Us",
    // buttonLink: "/contact",
  },
  {
    textPosition: "text-left",
    bgImage: "bg_image--1",
    category: "",
    // title: "The recovery you deserve.",
    // description: "Laredo Family Health Center provides professional care.",
    // buttonText: "Contact Us",
    // buttonLink: "/contact",
  },
];

const ServiceListOne = [
  {
    icon: <FiCast />,
    title: "Auto Accident Care",
    description:
      "Laredo Family Health Center can provide you with the care you need today.",
  },
  {
    icon: <FiLayers />,
    title: "Slip & Fall",
    description:
      "Laredo Family Health Center can provide you with the care you need today.",
  },
  {
    icon: <FiHeart />,
    title: "18-Wheeler Accidents",
    description:
      "Laredo Family Health Center can provide you with the care you need today.",
  },
  {
    icon: <FiHeart />,
    title: "Commercial Accidents",
    description:
      "Laredo Family Health Center can provide you with the care you need today.",
  },
];

const starndardService = [
  {
    image: "/images/Electric Stimulation.jpg",
    title: "Electric Stimulation",
    description:
      "It is used in treating conditions like sprains, arthritis, back pain scoliosis and sciatica. Electric stimulation can be muscular, general and transcutaneous electrical nerve stimulation (TENS). The muscular type of electric stimulation seeks to strengthen the muscles by reducing muscle spasms.",
  },
  {
    image: "/images/Adjustment.jpg",
    title: "Chiropractic Adjustments",
    description:
      "During a typical chiropractic adjustment, the doctor places you in a specific position to treat affected areas. The doctor uses his or her hands to apply a controlled, sudden force to a joint, pushing it beyond its usual range of motion. You may hear popping or cracking sounds as the doctor moves your joints during the treatment session. ",
  },
  {
    image: "/images/ultrasound.jpg",
    title: "Ultrasound",
    description:
      "Ultrasound therapy uses sound waves to produce vibrations, which create heat that penetrates deep within the tissues. The heat generated helps relax musculature, increase blood flow, speed up healing, and reduce inflammation of the injured tissue.",
  },
  {
    image: "/images/Xray picture.jpg",
    title: "X-Rays",
    description:
      "Before the chiropractor can manipulate the spine and skeletal system; they need to be precise on the affected region. Thus, it is sometimes necessary to undergo X-ray analysis imaging before a chiropractic adjustment.",
  },
];

class CorporateBusiness extends Component {
  constructor() {
    super();
    this.state = {
      isOpen: false,
    };
    this.openModal = this.openModal.bind(this);
  }
  openModal() {
    this.setState({ isOpen: true });
  }
  render() {
    var namesItemOne = [
      "Mon-Fri: 8:30AM - 6:00PM",
      "Sat: 9:00AM - 12:00PM",
      "Address: 120 West Village Blvd Ste. 202, Laredo, TX 78041",
    ];
    var namesItemTwo = ["956 725-5620", "956 725-5565"];

    return (
      <Fragment>
        <Helmet pageTitle="Laredo Family Health Center" />
        <Element name="home" className="element" />
        {/* Start Header Area  */}
        <Header
          headerPosition="header--static logoresize"
          logo="all-dark"
          color="color-black"
        />
        {/* End Header Area  */}

        {/* Start Slider Area   */}
        <div className="slider-wrapper">
          <div className="slider-activation">
            <Slider className="rn-slick-dot dot-light" {...slideSlick}>
              {SlideList.map((value, index) => (
                <div
                  className={`slide slide-style-2 slider-box-content without-overlay d-flex align-items-center justify-content-center bg_image ${value.bgImage}`}
                  key={index}
                >
                  <div className="container">
                    <div className="row">
                      <div className="col-lg-12">
                        <div className={`inner ${value.textPosition}`}>
                          {value.category ? <span>{value.category}</span> : ""}
                          {value.title ? (
                            <h1 className="title">{value.title}</h1>
                          ) : (
                            ""
                          )}
                          {value.description ? (
                            <p className="description">{value.description}</p>
                          ) : (
                            ""
                          )}
                          {value.buttonText ? (
                            <div className="slide-btn">
                              <Link
                                className="rn-button-style--2 btn-solid"
                                to="location"
                                spy={true}
                                smooth={true}
                                duration={500}
                              >
                                Contact Us
                              </Link>
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </Slider>
          </div>
        </div>
        {/* End Slider Area   */}

        {/* Start Service Area */}
        <div className="service-area ptb--30 bg_color--1">
          <div className="container">
            <div className="row service-one-wrapper">
              {ServiceListOne.map((val, i) => (
                <div
                  className="col-xl-3 col-lg-3 col-md-6 col-sm-6 col-12"
                  key={i}
                >
                  <div className="service service__style--2">
                    {/* <div className="icon">
                                                {val.icon}
                                            </div> */}
                    <div className="content">
                      <h3 className="title">{val.title}</h3>
                      <p>{val.description}</p>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
        {/* End Service Area */}

        {/* Start Featured Service Area  */}
        <Element name="care" className="element" />
        <div
          className="rn-featured-service-area pt--90 pb--120 bg_color--5"
          id="care"
        >
          <div className="container">
            <div className="row">
              {/* Start Single Service  */}
              <div className="col-lg-3 col-md-6 col-12 mt--30">
                <div className="section-title">
                  <h2 className="title">Care Provided</h2>
                  <p>You deserve the proper care. We are your solution.</p>
                  {/* <div className="service-btn">
                    <a className="btn-transparent rn-btn-dark" href="/service">
                      <span className="text">Contact Us</span>
                    </a>
                  </div> */}
                </div>
              </div>
              {/* End Single Service  */}

              {/* Start Single Service  */}
              <div className="col-lg-9">
                <div className="row">
                  {starndardService.map((value, index) => (
                    <div className="col-lg-4 col-md-4 mt--30" key={index}>
                      <div className="standard-service">
                        <div className="thumbnai">
                          <img
                            src={`/assets/${value.image}`}
                            alt="Laredo Family Health Center"
                          />
                        </div>
                        <div className="content">
                          <h3>
                            <a href="/service-details">{value.title}</a>
                          </h3>
                          <p>{value.description}</p>
                          {/* <a
                            className="btn-transparent rn-btn-dark"
                            href="/service-details"
                          >
                            <span className="text">Read More</span>
                          </a> */}
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
              {/* End Single Service  */}
            </div>
          </div>
        </div>
        {/* End Featured Service Area  */}

        {/* Start Counterup Area */}
        {/* <div
          className="counterup-area pb--80 pt--40 bg_image bg_image--17 theme-text-white"
          data-black-overlay="7"
        >
          <div className="container">
            <CounterOne />
          </div>
        </div> */}
        {/* End Counterup Area */}

        {/* Start About Area  */}
        <div className="rn-about-area ptb--120 bg_color--5">
          <div className="container">
            <div className="row row--35 align-items-center">
              <div className="col-lg-4">
                <div className="thumbnail">
                  <img
                    src="assets/images/laredo-family-health-center-interior.jpeg"
                    alt="Laredo Family Health Center Interior Waiting Area"
                  />
                </div>
              </div>
              <div className="col-lg-8">
                <div className="about-inner inner">
                  <div className="section-title">
                    <h2 className="title">
                      Laredo Family Health Center Difference
                    </h2>
                    <div className="row row--35 align-items-center">
                      <div class="col-lg-6">
                        <p>We will wait for payment with approved coverage.</p>
                        <p>We work with your attorney.</p>
                        <p>3rd Party Insurance accepted.</p>
                        <p>Personal Injury Protection (PIP)</p>
                        <p>Same Day Appointments</p>
                        <p>We accept self-pay consultations</p>
                        <p>
                          Transportaion Provided{" "}
                          <small>(some exclusions may apply)</small>
                        </p>
                      </div>
                      <div class="col-lg-6">
                        <p>Minor accidents can cause underlying injuries.</p>
                        <p>Symptoms may not appear until weeks later.</p>
                        <p>Treating aches and pains for more than 16 years.</p>
                        <p>MD referrals that will wait for payment.</p>
                        <p>Walk-Ins Welcome</p>
                        <p>
                          We accept Slip & Fall, Auto, and Commercial accidents.
                        </p>
                      </div>
                    </div>
                  </div>
                  {/* <div className="accordion-wrapper mt--30">
                    <Accordion01 />
                  </div> */}
                  {/* <div className="about-button mt--50">
                    <a className="rn-button-style--2 btn-solid" href="/about">
                      See how it works
                    </a>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End About Area  */}
        <Element name="team" className="element" />
        {/* Start Team Area  */}
        <div className="rn-team-area ptb--120 bg_color--1">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="section-title service-style--3 text-center mb--25 mb_sm--0">
                  <h2 className="title">Your Health Care Team</h2>
                  <p>Meet our friendly staff members.</p>
                </div>
              </div>
            </div>
            <div className="row">
              <Team
                column="col-lg-3 col-md-6 col-sm-6 col-12 mt--30"
                teamStyle=""
                item="5"
              />
            </div>
          </div>
        </div>
        {/* End Team Area  */}
        <Element name="location" className="element" />
        {/* Start About Area  */}
        <div className="rn-about-area ptb--120 bg_color--5">
          <div className="container">
            <div className="row row--35 align-items-center">
              <div className="col-lg-6 order-2 order-lg-1">
                <div className="about-inner inner">
                  <div className="section-title">
                    <h2 className="title">Call or visit Today</h2>
                    <p className="description">
                      Your pain deserves the proper treatment. Visit us today or
                      give us a call.
                    </p>
                  </div>
                  <div className="mt--30">
                    <h4>Visit Us</h4>
                    <ul className="list-style--1">
                      {namesItemOne.map((name, index) => {
                        return <li key={index}>{name}</li>;
                      })}
                    </ul>
                  </div>
                  <div className="mt--30">
                    <h4>Contact Us</h4>
                    <ul className="list-style--1">
                      {namesItemTwo.map((name, index) => {
                        return (
                          <li key={index}>
                            {["Phone", "Fax"][index]}:{" "}
                            <a href={`tel:${name.replace("-", "")}`}>{name}</a>
                          </li>
                        );
                      })}
                      <li>Email: info@laredofhc.com</li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 order-2 order-lg-1">
                <div className="about-inner inner">
                  <img src="assets/images/laredo-family-health-center.jpeg" alt="Laredo Family Health Center Building" />
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Start Footer Style  */}
        <FooterTwo />
        {/* End Footer Style  */}

        {/* Start Back To Top */}
        <div className="backto-top">
          <ScrollToTop showUnder={160}>
            <FiChevronUp />
          </ScrollToTop>
        </div>
        {/* End Back To Top */}
      </Fragment>
    );
  }
}
export default CorporateBusiness;
