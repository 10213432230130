import React from "react";
import { FaFacebookF, FaLinkedinIn, FaTwitter } from "react-icons/fa";

let data = [
  {
    images: "./assets/images/members/drmelissa-oguerra.jpg",
    title: "MELISSA O GUERRA D. C.",
    designation: "CHIROPRACTOR",
  },
  {
    images: "./assets/images/members/patty-canchola.jpg",
    title: "PATTY CANCHOLA",
    designation: "OFFICE MANAGER",
  },
  {
    images: "./assets/images/members/claudia-contreras.jpeg",
    title: "CLAUDIA CONTRERAS",
    designation: "BILLING AND COLLECTIONS",
  },
  {
    images: "./assets/images/members/maricela-jimenez.jpeg",
    title: "MARICELA JIMENEZ",
    designation: "THERAPY ASSISTANCE",
  },
  {
    images: "./assets/images/members/amy-martinez.jpeg",
    title: "AMY MARTINEZ ",
    designation: "FRONT DESK",
  },
];

export default data;
