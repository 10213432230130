import React from 'react'
import data from "./data";
const TeamTwo = (props) => {
    const itemSlice = data.slice(0 , props.item)
    return (
        <div className="row">
            {itemSlice.map((value , i ) => (
                <div className={`${props.column}`} key={i}>
                    <div className={`team-static ${props.teamStyle}`}>
                        <div className="thumbnail">
                            <img src={value.images} alt={value.title}/>
                        </div>
                        <div className="inner">
                            <div className="content">
                                <h4 className="title">{value.title}</h4>
                                <p className="designation">{value.designation}</p>
                            </div>
                        </div>
                    </div>
                </div>
            ))}
        </div>
    )
}
export default TeamTwo
