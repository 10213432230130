import React, { Component } from "react";
import { FiX, FiMenu } from "react-icons/fi";
import {
  Link,
  animateScroll as scroll,
  scroller,
} from "react-scroll";

class HeaderFive extends Component {
  constructor(props) {
    super(props);
    this.menuTrigger = this.menuTrigger.bind(this);
    this.CLoseMenuTrigger = this.CLoseMenuTrigger.bind(this);
    this.scrollToTop = this.scrollToTop.bind(this);
    //  this.subMetuTrigger = this.subMetuTrigger.bind(this);
    window.addEventListener("load", function () {
      console.log("All assets are loaded");
    });
  }
  scrollToTop() {
    scroll.scrollToTop();
  }
  scrollTo() {
    scroller.scrollTo("scroll-to-element", {
      duration: 800,
      delay: 0,
      smooth: "easeInOutQuart",
    });
  }
  menuTrigger() {
    document.querySelector(".header-wrapper").classList.toggle("menu-open");
  }
  CLoseMenuTrigger() {
    document.querySelector(".header-wrapper").classList.remove("menu-open");
  }
  render() {
    var elements = document.querySelectorAll(".has-droupdown > a");
    for (var i in elements) {
      if (elements.hasOwnProperty(i)) {
        elements[i].onclick = function () {
          this.parentElement
            .querySelector(".submenu")
            .classList.toggle("active");
          this.classList.toggle("open");
        };
      }
    }

    const { logo, color, headerPosition } = this.props;
    let logoUrl;

    logoUrl = (
      <img
        src="assets/images/laredo-family-health-center.png"
        alt="Laredo Family Health Center Logo"
      />
    );

    return (
      <header
        className={`header-area header--fixed sticky formobile-menu ${headerPosition} ${color}`}
      >
        <div className="header-wrapper" id="header-wrapper">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-3 col-md-4 col-6">
                <div className="header-left">
                  <div className="logo">
                    <a href="/">{logoUrl}</a>
                  </div>
                </div>
              </div>
              <div className="col-lg-9 col-md-8 col-6">
                <div className="header-right justify-content-end">
                  <nav className="mainmenunav d-lg-block">
                    <ul className="mainmenu">
                      <li>
                        <Link to="home" spy={true} smooth={true} duration={500}>
                          Home
                        </Link>
                      </li>
                      <li>
                        <Link to="care" spy={true} smooth={true} duration={500}>
                          Care
                        </Link>
                      </li>
                      <li>
                        <Link to="team" spy={true} smooth={true} duration={500}>
                          Our Team
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="location"
                          spy={true}
                          smooth={true}
                          duration={500}
                        >
                          Contact Us
                        </Link>
                      </li>
                      {/* <li>
                      <Link to="team" spy={true} smooth={true} duration={500}>
                          
                        </Link>
                      </li> */}
                    </ul>
                  </nav>
                  {/* Start Humberger Menu  */}
                  <div className="humberger-menu d-block d-lg-none pl--20">
                    <span
                      onClick={this.menuTrigger}
                      className="menutrigger text-white"
                    >
                      <FiMenu />
                    </span>
                  </div>
                  {/* End Humberger Menu  */}
                  <div className="close-menu d-block d-lg-none">
                    <span
                      onClick={this.CLoseMenuTrigger}
                      className="closeTrigger"
                    >
                      <FiX />
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
    );
  }
}
export default HeaderFive;
