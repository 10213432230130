import React, { Component } from "react";
import ReactDOM from "react-dom";
import "./index.scss";
import PageScrollTop from "./component/PageScrollTop";
import CorporateBusiness from "./home/CorporateBusiness";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import * as serviceWorker from "./serviceWorker";

class Root extends Component {
  render() {
    return (
      <BrowserRouter basename={"/"}>
        <PageScrollTop>
          <Switch>
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/`}
              component={CorporateBusiness}
            />
            <Route
              path={`${process.env.PUBLIC_URL}/404`}
              component={CorporateBusiness}
            />
            <Route component={CorporateBusiness} />
          </Switch>
        </PageScrollTop>
      </BrowserRouter>
    );
  }
}

ReactDOM.render(<Root />, document.getElementById("root"));
serviceWorker.register();
